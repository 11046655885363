
/* eslint-disable @typescript-eslint/camelcase */
import {computed, defineComponent, onMounted, onUpdated, ref, watch} from "vue";
import { useStore } from "vuex";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import ActivitiesDataTable from "@/components/system/Tables/ActivitiesDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import ActivitiesTypeDropdown from "@/components/dropdown/ActivitiesTypeDropdown.vue";
import ViewLogModal from "@/components/system/Modals/ViewLogModal.vue";
import { Modal } from "bootstrap";
import {useAbility} from "@casl/vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "activity-log",
  components: {
    ActivitiesDataTable,
    ActivitiesTypeDropdown,
    LoadingSpinner,
    ViewLogModal,
    Pagination
  },
  setup() {
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const pagination = {
      current_page: 1,
      total_pages: 1
    }
    const data = computed(
        () => store.getters['ActivitiesModule/getAll']
    );
    const viewedLog = ref();
    const filterType = ref(null);
    const fetchData = (page: number, filter?) => {
      isLoading.value = true;
      store.dispatch("ActivitiesModule/fetchAdmin", { page: page, filter: filter ?? null })
          .finally(() => {
            isLoading.value = false;
            pagination.current_page = data.value.current_page;
            pagination.total_pages = data.value.last_page;
          })
    }
    onMounted(() => {
      setCurrentPageTitle("Activity log");
      fetchData(1);
    });
    watch(() => filterType.value, () => {
      fetchData(1, filterType.value)
    });

    const handlePropertiesModal = (event) => {
      viewedLog.value = event;
      const modal = new Modal(document.getElementById('viewLogModal'));
      modal.show();
    }
    const handlePageChange = (page: number) => {
      fetchData(page);
    }

    return{
      data,
      filterType,
      viewedLog,
      handlePropertiesModal,
      handlePageChange,
      pagination,
      isLoading
    }
  }
});
