<template>
  <div class="table-responsive">
    <table
        class="
            table table-row-dashed table-row-gray-300
            align-middle
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-150px">Type</th>
          <th class="min-w-140px">When</th>
          <th class="min-w-120px">Description</th>
          <th class="min-w-120px text-end">Properties</th>
        </tr>
      </thead>
      <tbody>
        <tr class="fs-7 fw-bold" v-for="entity in tableData" :key="entity.id">
          <td>{{ entity.log_name }}</td>
          <td>{{ entity.created_at }}</td>
          <td>{{ entity.description }}</td>
          <td class="text-end">
            <div class="d-flex justify-content-end">
              <button @click="$emit('openPropertiesModal',entity)" class="d-flex align-items-center btn btn-sm btn-primary h-30px">
                <span>More</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "activities-data-table",
  props: {
    tableData: Object
  }
})
</script>