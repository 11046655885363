<template>

  <div class="modal fade" id="viewLogModal" data-toggle="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="viewLogModalLabel">Log event information</h5>
          <div
              class="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>
        <div class="modal-body">
          <div v-if="data" class="d-flex flex-column">
            <div class="mx-2 fs-5 fw-bold" v-for="(entity, key) in data.properties" :key="entity">
              {{ key }} : {{ entity }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "view-log-modal",
  props: {
    data: Object
  }
});
</script>
