
import {computed, defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  name: "activities-types-dropdown",
  setup() {
    const store = useStore();
    const value = ref();
    const activityTypes = computed(
        () => store.getters['ListsModule/activityTypes']
    );
    onMounted(() => {
      store.dispatch("ListsModule/fetchActivityTypes")
    })
    return {
      value,
      activityTypes
    }
  }
});
